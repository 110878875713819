.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8rem;
}

.footer-email {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.footer-email h4 {
  display: inline-block;
  font-weight: 300;
  margin: 0;
}

.footer-email button {
  color: white;
  background-color: #393d3f4f;
  border: none;
  border-radius: 4px;
  transition: color 100ms ease-in-out, background-color 100ms ease-in-out;
  padding: 1px 6px;
  margin-left: 6px;
}

.footer-email button:hover {
  background-color: #393d3fa4;
  color: white;
}

.footer-email button:active {
  background-color: #393d3f;
}
