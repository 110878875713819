.writing-portflio .article {
  margin-bottom: 2rem;
  transition: transform 80ms ease-in-out;
}

.writing-portflio h4 {
  font-weight: 700;
  font-family: "Lora", serif;
}

.writing-portflio .article__title {
  font-size: 18px;
}

.writing-portflio .article:hover {
  transform: scale(101.5%);
}

.writing-portflio .article:hover img {
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.2)) saturate(110%)
    contrast(110%);
}
