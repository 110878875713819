@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
.App {
  padding: 0 0.5rem;
  color: #393d3f;
  font-family: "Roboto", sans-serif;
}

.container {
  max-width: 1080px !important;
}

.section {
  padding: 2rem 2rem 0;
}

@media (max-width: 992px) {
  .section {
    padding: 2rem 0 0;
  }
}

.sub-heading {
  padding-top: 6rem;
  text-align: center;
  font-weight: 700;
  font-family: "Lora", serif;
}

.sub-heading-divider {
  opacity: 100%;
  color: #393d3f;
}

.contact {
  display: flex;
  gap: 10px;
}

.contact .contact-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #393d3f;
  width: 34px;
  height: 34px;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: opacity 150ms ease-in-out;
}

.contact .contact-icon:hover {
  opacity: 70%;
}

.article {
  display: block;
  color: #393d3f;
  text-decoration: none;
}

.article img {
  transition: filter 120ms ease-in-out, transform 120ms ease-in-out;
}
