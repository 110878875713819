.editing-portflio .article {
  margin-bottom: 3rem;
}

.editing-portflio .article:hover img {
  transform: scale(101%);
  filter: saturate(103%) contrast(105%);
}

.editing-portflio h3 {
  font-weight: 700;
  font-family: "Lora", serif;
}
