.credentials {
  display: flex;
  justify-content: center;
}

.credentials .credentials__image {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}

.credentials .credentials__image img {
  max-height: 50px;
  max-width: 60%;
}

.credentials .credentials__text {
  max-width: 230px;
  text-align: center;
  font-size: 13px;
  margin: 0 auto;
}

@media (max-width: 575px) {
  .credentials {
    gap: 4rem;
  }
}
